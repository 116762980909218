//
// Rotate
//

$rotate-transition: transform 0.3s ease;

.rotate {
  display: inline-flex;
  align-items: center;
}

@each $value in (90, 180, 270) {
  .rotate-#{$value} {
    transition: $rotate-transition;
    backface-visibility: hidden;

    .collapsible:not(.collapsed) > &,
    .show > &,
    .active > & {
      transform: rotateZ(#{$value}deg);
      transition: $rotate-transition;

      [direction="rtl"] & {
        transform: rotateZ(-#{$value}deg);
      }
    }
  }

  .rotate-n#{$value} {
    transition: $rotate-transition;
    backface-visibility: hidden;

    .collapsible:not(.collapsed) > &,
    .show > &,
    .active > & {
      transform: rotateZ(-#{$value}deg);
      transition: $rotate-transition;

      [direction="rtl"] & {
        transform: rotateZ(#{$value}deg);
      }
    }
  }
}
