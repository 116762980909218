/* Swiper Navigation : Begins */
.swiper-button-disabled {
  color: #f5f5f5;
}

.swiper-button-next {
  color: #ffffff;
}

.swiper-button-prev {
  color: #f5f5f5;
}
/* Swiper Navigation : Ends */
